// https://images.unsplash.com/flagged/photo-1574874897534-036671407eda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80
import { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";

import "../css/About.css";
import bvbImg from "../img/bvb-2.jpg";
import useWindowWidth from "../utils/useWindowWidth";

gsap.registerPlugin(ScrollTrigger);
const About = () => {
  const { width } = useWindowWidth();

  const aboutRef = useRef();
  useEffect(() => {
    const about = aboutRef.current;

    const intro = gsap.timeline();
    if (width >= 800) {
      intro
        .set(about.querySelector(".about-header__h1"), {
          color: "transparent",
        })
        .set(about.querySelector(".about-header__p"), {
          color: "transparent",
          y: "5%",
        })
        .set(about.querySelector(".about-header__img--container"), {
          height: () => document.documentElement.clientHeight / 2,
          y: "10%",
        })
        .set(about.querySelectorAll(".about-header__h1--line"), {
          y: "150%",
          rotate: 5,
          color: "transparent",
        })
        .to(about.querySelectorAll(".about-header__h1--line"), {
          y: "0%",
          rotate: 0,
          stagger: 0.04,
          color: "black",
        })

        .to(
          about.querySelector(".about-header__img--container"),
          {
            y: "0%",
            height: () =>
              about.querySelector(".about-header__img--container")
                .offsetHeight +
              document.documentElement.clientHeight / 10,
          },
          "-=0.5"
        )
        .to(about.querySelector(".about-header__p"), {
          color: "black",
          y: 0,
        });
    } else {
      intro
        .set(about.querySelector(".about-header__h1"), {
          color: "transparent",
        })
        .set(about.querySelector(".about-header__p"), {
          color: "transparent",
          y: "5%",
        })
        .to(about.querySelectorAll(".about-header__h1--line"), {
          y: "0%",
          rotate: 0,
          stagger: 0.04,
          color: "black",
        })
        .to(about.querySelector(".about-header__p"), {
          color: "black",
          y: 0,
        });
    }
  }, []);
  useEffect(() => {
    const about = aboutRef.current;
    let scrollTl = gsap.timeline({
      scrub: 1,
      toggleActions: "restart none none reverse",
    });

    const paragraphImages = about.querySelectorAll(".about-paragraph__img");
    gsap.utils.toArray(paragraphImages).forEach((section, i) => {
      const heightDiff = section.offsetHeight;
      // section.offsetHeight - section.parentElement.offsetHeight;

      gsap.fromTo(
        section,
        {
          y: -heightDiff,
        },
        {
          scrollTrigger: {
            trigger: section,
            invalidateOnRefresh: true,
            scrub: true,
            end: "bottom top",
          },
          y: 0,
          duration: 1,
          ease: "none",
        }
      );
    });

    const paragraphP = about.querySelectorAll(".about-paragraph__p");
    const paragraphH1 = about.querySelectorAll(".about-paragraph__h1");

    animateElementIn(paragraphH1, 20);
    animateElementIn(paragraphP);

    function animateElementIn(el, y = 10, duration = 0.5) {
      let scrollTl = gsap.timeline({
        scrub: 1,
        toggleActions: "restart none none reverse",
      });

      if (el.length > 0) {
        el.forEach((e) => {
          gsap.set(e, {
            opacity: 0,
            y: y,
          });
          gsap.to(e, {
            scrollTrigger: {
              trigger: e,
              start: "top 80%",
              toggleActions: "restart none none reverse",

              // end: "+=500",
              end: () => document.documentElement.clientHeight,
            },
            y: 0,
            opacity: 1,
            duration: duration,
          });
        });
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Um Mig</title>
        <link rel="canonical" href="https://birgirvalur.is" />
      </Helmet>
      <div className="about-container" ref={aboutRef}>
        <header className="about-header">
          <div className="about-header--text">
            <h1 className="about-header__h1">
              <div className="about-header__h1--line">Metnaður,</div>
              <div className="about-header__h1--line">áreiðanleiki</div>
              <div className="about-header__h1--line">og heiðarleiki</div>
            </h1>
            <p className="about-header__p">
              er það sem ég stend fyrst og fremst fyrir. Ég legg upp úr því að
              veita framúrskarandi þjónustu og tryggi að þínir hagsmunir verði
              alltaf hafðir í forgrunni. Ásamt því að vera í námi til
              löggildingar fasteigna- og skipasala þá er ég að útskrifast með BA
              gráðu i lögfræði frá Háskólanum í Reykjavík. Ég nýti þekkingu mína
              úr lögfræðináminu í öllu sem ég tek mér fyrir hendur og nýtist hún
              einstaklega vel í starfi mínu. Samhliða því að tryggja að
              söluferlið allt sé í samræmi við lög og reglur fæst einnig með
              þessu móti hámarks söluverð fyrir þína fasteign.
            </p>
          </div>
          <div className="about-header__img--container">
            <img src={bvbImg} alt="fake biggi" className="about-header__img" />
          </div>
        </header>

        <main className="about__main">
          <div className="about-paragraph about-paragraph--alternate">
            <div className="about-paragraph-text">
              <h1 className="about-paragraph__h1">Alda fasteignasala </h1>
              <p className="about-paragraph__p">
                Ég starfa hjá fasteignasölunni Alda, undir leiðsögn löggilta
                fasteignasala sem hafa unnið sem slíkir í fjölda ára og búa að
                mikilli reynslu. Ég tek að mér sölu á öllum eignum ásamt því að
                finna leigjendur í íbúðar- og atvinnuhúsnæði. Ég hef gaman að
                krefjandi verkefnum og nýt mín best þegar ég hef nóg að gera og
                í ýmsu að snúast.
              </p>
            </div>
            <div className="about-paragraph__img--wrapper">
              <div
                className="about-paragraph__img"
                style={{
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1615911577983-e2bb805354b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1343&q=80)",
                }}
              ></div>
            </div>
          </div>
          <div className="about-paragraph">
            <div className="about-paragraph-text">
              <h1 className="about-paragraph__h1">
                Af hverju ákvað ég að verða fasteignasali?
              </h1>
              <p className="about-paragraph__p">
                Áhugi minn á fasteignum hefur alltaf verið til staðar. Frá því
                ég var lítill hef ég horft á þætti um hús, t.d. Grand Design,
                heimsókn og ofl. Eftir að ég byrjaði í lögfræði fór áhugi minn á
                starfi fasteignasala að aukast mikið. Ég fór að kynna mér
                hvernig fasteignir eru seldar og hvaða atriði þarf að hafa í
                huga. Fljótlega tók ég þá ákvörðun að skrá mig í nám til
                löggildingar fasteigna- og skipasala og stefni ég að því að vera
                orðinn löggiltur fasteignasali vorið 2023.
              </p>
            </div>
            <div className="about-paragraph__img--wrapper">
              <div
                className="about-paragraph__img"
                style={{
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80)",
                }}
              ></div>
            </div>
          </div>
          <div className="about-paragraph about-paragraph--alternate">
            <div className="about-paragraph-text">
              <h1 className="about-paragraph__h1">Nám</h1>
              <p className="about-paragraph__p">
                Eftir að hafa útskrifast með stúdentspróf af náttúrufræðibraut
                frá Kvennaskólanum í Reykjavík lá leiðin beint í Flugskóla
                Íslands. Þar kláraði ég einka- og atvinnuflugmannsnám. Lögfræði
                í Háskólanum í Reykjavík tók við eftir flugnámið og stefni ég á
                að útskrifast með BA-gráðu þaðan vorið 2022. Haustið 2021 hóf ég
                nám til löggildingar fasteigna- og skipasala og stefni á að
                verða orðinn löggiltur fasteignasali vorið 2023.
              </p>
            </div>
            <div className="about-paragraph__img--wrapper">
              <div
                className="about-paragraph__img"
                style={{
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1316&q=80)",
                }}
              ></div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default About;
