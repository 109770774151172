import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { Helmet } from "react-helmet";

// animations
import Animate from "../componments/AnimateRealEstate";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";

import "../css/RealEstate.css";

const suburbs = [
  "Seltjarnarnes",
  "Mosfellsbær",
  "Hafnarfjörður",
  "Garðabær",
  "Kópavogur",
  "Reykjavík",
];

const RealEstate = () => {
  const [everyRealEstate, setEveryRealEstate] = useState(undefined);
  const [realEstate, setRealEstate] = useState([]);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(true);
  const containerRef = useRef(null);
  let scrollSpeed = 1;

  useEffect(() => {
    if (scrollSpeed > 4) {
      scrollSpeed = 1;
    }
  }, [scrollSpeed]);

  useEffect(() => {
    getRealEstates();
    if (realEstate.length > 0) {
      Animate();
    }
  }, [everyRealEstate]);

  function getRealEstates() {
    if (!everyRealEstate) {
      fetch("/the-real-estate")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setRealEstate(data);
            setEveryRealEstate(data);
            setPending(false);
          } else setError("something went wrong");
        });
    }
  }

  function splitText(txt) {
    let characters = txt.split("");
    return (
      <span className="word" data-word={txt} style={{ "--word-index": 0 }}>
        {characters.map((char, charIndex) => (
          <span
            className="char"
            data-char={char}
            style={{ "--char-index": charIndex }}
            key={charIndex}
          >
            {char}
          </span>
        ))}
      </span>
    );
  }

  return (
    <div data-scroll-section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fasteignir</title>
        <link rel="canonical" href="https://birgirvalur.is" />
      </Helmet>
      <LocomotiveScrollProvider
        options={{
          smooth: true,
        }}
        containerRef={containerRef}
      ></LocomotiveScrollProvider>
      <div
        className="real-estate noscroll"
        data-scroll-container
        ref={containerRef}
      >
        <header className="real-estate__header">
          <h1
            className="real-estate__h1 real-estate__title"
            data-scroll
            data-scroll-speed="2"
            data-scroll-position="top"
          >
            Fasteignir Okkar
          </h1>
          <p
            className="real-estate__p real-estate__text"
            data-scroll
            data-scroll-speed="1"
            data-scroll-position="top"
          >
            Eðal íbúðir og opin hús. It is difficult not to feel that the
            unconscious aim in the most typical modern pleasure resorts is a
            return to the womb. For there, too, one was never alone, one never
            saw daylight, the temperature was always regulated, one did not have
            to worry about work or food, and one's thoughts, if any, were
            drowned by a continuous rhythmic throbbing.
          </p>
        </header>
        <main className="real-estate__main">
          {realEstate &&
            realEstate.map(({ imgUrl, street, id }, index) => (
              <Link to={`/real-estate/${id}`} key={id}>
                <figure
                  data-scroll
                  data-scroll-speed={index + 1.5}
                  data-scroll-position="middle"
                  className="real-estate__item"
                >
                  <div className="real-estate__item-img">
                    <div
                      className="real-estate__item-imginner"
                      style={{
                        backgroundImage: `url(https:${imgUrl.fields.file.url})`,
                      }}
                    ></div>
                  </div>
                  <figcaption className="real-estate__item-caption">
                    <h2 className="real-estate__item-title" data-splitting>
                      {splitText(street)}
                    </h2>
                  </figcaption>
                </figure>
              </Link>
            ))}
        </main>
        {/* <svg className="cursor" width="30" height="30" viewBox="0 0 30 30">
          <circle className="cursor__inner" cx="15" cy="15" r="7.5" />
        </svg> */}
      </div>
    </div>
  );
};
export default RealEstate;
