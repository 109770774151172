import { useParams } from "react-router";
import { useState, useEffect } from "react";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Bold = ({ children }) => <b>{children}</b>;

const Text = ({ children }) => <p className="single-news__p">{children}</p>;

const SingleNews = () => {
  let { id } = useParams();
  const [singleNews, setSingleNews] = useState();
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    fetch(`/news-article/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 204) {
          setError("no content");
          setPending(false);
        } else if (data) {
          setSingleNews(data);
          setPending(false);
        } else setError("something went wrong");
      });
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  };

  return (
    <div className="single-news">
      {singleNews && (
        <>
          <img
            src={`https:${singleNews.imgUrl.fields.file.url}`}
            alt=""
            className="single-news__img"
          />
          <h1 className="single-news__h1">{singleNews.title}</h1>
          {documentToReactComponents(singleNews.paragraphs, options)}
        </>
      )}
      {pending && <p>Hleður...</p>}
      {error && <p>{error}</p>}
    </div>
  );
};
export default SingleNews;
