import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../css/Estate.css";
const Bold = ({ children }) => <b>{children}</b>;

const Text = ({ children }) => <p className="estate__p">{children}</p>;

const Estate = () => {
  let { id } = useParams();
  const [realEstate, setRealEstate] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    fetch(`/the-real-estate/${id}`)
      .then((response) => response.json())
      .then((data) => {
        // if status code is 204
        // if (data == 204) {
        //   setError("no content");
        //   setPending(false);
        // } else if (data) {
        //   setRealEstate(data[0]);
        //   setPending(false);
        // } else setError("something went wrong");

        if (data.status == 204) {
          setError("no content");
          setPending(false);
        } else if (data) {
          setRealEstate(data);
          setPending(false);
        } else setError("something went wrong");
      });
  }, []);

  const options = {
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  };

  return (
    <div className="estate">
      {realEstate && (
        <>
          <img
            src={`https:${realEstate.imgUrl.fields.file.url}`}
            alt="the house on sale"
            className="estate__img"
          />
          <div className="estate-header">
            <h1 className="estate__h1">{realEstate.title}</h1>
            <p className="estate__p">{realEstate.postCode}</p>
            <h1 className="estate__h1">{realEstate.price} kr</h1>
          </div>
          <p className="estate__p">
            {realEstate.area} m², {realEstate.type}, {realEstate.rooms} herbergi
          </p>
          {documentToReactComponents(realEstate.about, options)}
        </>
      )}
      {pending && <p>Hleður...</p>}
      {error && <p>{error}</p>}
    </div>
  );
};
export default Estate;
