import React, { Suspense } from "react";
import { useRef, useEffect } from "react";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
import "./css/Home.css";

// const News = React.lazy(() => import("./componments/News"));
// const Decision = React.lazy(() => import("./componments/Decision"));
// const About = React.lazy(() => import("./componments/About"));
// const Testimonials = React.lazy(() => import("./componments/Testimonials"));
// const Connect = React.lazy(() => import("./componments/Connect"));

import News from "./componments/News";
import Decision from "./componments/Decision";
import About from "./componments/About";
// import Testimonials from "./componments/Testimonials";
import Connect from "./componments/Connect";
// import livingRoomVideo from "./living-room.mp4";
import livingRoomVideo from "./la-video.mp4";
gsap.registerPlugin(TextPlugin, ScrollTrigger);

const Home = () => {
  const tl = useRef();
  const homeRef = useRef();

  useEffect(() => {
    const home = homeRef.current;

    let scrollTl = gsap.timeline({
      scrub: 1,
      toggleActions: "play none none play",
    });

    let aboutP = home.querySelectorAll(".about__p");
    aboutP.forEach((p) => {
      scrollTl.to(p, {
        scrollTrigger: {
          trigger: p,
          start: "top 90%",
          end: () => "+=" + p.offsetHeight * 2,
        },
        opacity: 1,

        y: -5,
        duration: 3,
      });
    });
    scrollTl.to(home.querySelector(".about__cta"), {
      scrollTrigger: {
        trigger: home.querySelector(".about__cta"),
        start: "top 80%",
        end: () => "+=" + home.querySelector(".about__cta").offsetHeight * 2,
      },
      opacity: 1,
      y: -5,
      duration: 3,
    });
    // scrollTl.to(home.querySelector(".decision-sell .decision__h1"), {
    //   scrollTrigger: {
    //     trigger: home.querySelector(".decision-sell"),
    //     start: "top 40%",
    //     end: () => "+=" + home.querySelector(".decision-sell").offsetHeight,
    //   },
    //   opacity: 1,
    //   y: -5,
    //   duration: 3,
    // });
    // scrollTl.to(home.querySelector(".decision-sell .decision__p"), {
    //   scrollTrigger: {
    //     trigger: home.querySelector(".decision-sell"),
    //     start: "top 35%",
    //     end: () => "+=" + home.querySelector(".decision-sell").offsetHeight,
    //   },
    //   opacity: 1,
    //   y: -5,
    //   duration: 3,
    // });
    // scrollTl.to(home.querySelector(".decision-sell .decision__cta"), {
    //   scrollTrigger: {
    //     trigger: home.querySelector(".decision-sell"),
    //     start: "top 30%",
    //     end: () => "+=" + home.querySelector(".decision-sell").offsetHeight,
    //   },
    //   opacity: 1,
    //   y: -5,
    //   duration: 3,
    // });
    // scrollTl.to(home.querySelector(".decision-buy .decision__h1"), {
    //   scrollTrigger: {
    //     trigger: home.querySelector(".decision-buy"),
    //     start: "top 40%",
    //     end: () => "+=" + home.querySelector(".decision-buy").offsetHeight,
    //   },
    //   opacity: 1,
    //   y: -5,
    //   duration: 3,
    // });
    // scrollTl.to(home.querySelector(".decision-buy .decision__p"), {
    //   scrollTrigger: {
    //     trigger: home.querySelector(".decision-buy"),
    //     start: "top 35%",
    //     end: () => "+=" + home.querySelector(".decision-buy").offsetHeight,
    //   },
    //   opacity: 1,
    //   y: -5,
    //   duration: 3,
    // });
    // scrollTl.to(home.querySelector(".decision-buy .decision__cta"), {
    //   scrollTrigger: {
    //     trigger: home.querySelector(".decision-buy"),
    //     start: "top 30%",
    //     end: () => "+=" + home.querySelector(".decision-buy").offsetHeight,
    //   },
    //   opacity: 1,
    //   y: -5,
    //   duration: 3,
    // });
  }, []);

  useEffect(() => {
    const home = homeRef.current;
    const titleQuery = home.querySelector(".home-header__h1");
    const subtitleQuery = home.querySelector(".home-header--subtitle");
    let delay = 6;
    const text = [
      {
        subtitle: "Framúrskarandi",
        title: "Þjónusta",
        delimiter: "",
      },

      {
        subtitle: "",
        title: "Metnaður",
        delimiter: "",
      },
      {
        // mommu ad kenna
        subtitle: "vönduð vinnubrögð",
        title: "Heiðarleiki",
        delimiter: "",
      },
      {
        // Make sure the last elements content equeals
        // the one in the html, for smooth transition.
        subtitle: "",
        title: "Áreiðanleiki",
        delimiter: "",
      },
    ];

    const headerTl = gsap.timeline({
      onComplete: function () {
        this.restart();
      },
    });

    text.forEach(({ title, subtitle }, i) => {
      headerTl
        .to(
          titleQuery,
          {
            text: {
              value: title,
              delimiter: "",
            },
            ease: "power2",
          },
          `+=${delay}`
        )
        .to(
          subtitleQuery,
          {
            text: {
              value: subtitle,
              delimiter: "",
            },
            ease: "power2",
          },
          `-=${delay / 5}`
        );
    });
  }, []);

  return (
    <div className="home" ref={homeRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Birgir Valur Birgisson</title>
        <link rel="canonical" href="https://birgirvalur.is" />
      </Helmet>
      <header className="home--header">
        <div className="home-header--text">
          <h5 className="home-header__h5"></h5>
          <p className="home-header__p home-header--subtitle"></p>
          <h1 className="home-header__h1">Áreiðanleiki</h1>
          <p className="home-header__p">Finndu þitt draumaheimili.</p>
          {/* <Link to="/real-estate" className="home-header--cta">
            Fasteigna Leit
          </Link> */}
        </div>
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="https://images.unsplash.com/photo-1577052185469-4c277a9463a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2374&q=80"
          id="bgvid"
        >
          <source src={livingRoomVideo} type="video/mp4" />
        </video>
      </header>
      <About />
      <Decision />
      {/* until we get actual testimonials :) */}
      {/* <Testimonials limit={3} /> */}
      <News />
      {/* <Connect /> */}
    </div>
  );
};

export default Home;
