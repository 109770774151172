import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "../css/Valuation.css";
import svg from "../svg/arrow.svg";

import bvbImg from "../img/bvb-4.jpg";

const ValuationForm = ({ title = "", subtitle = "", action = "" }) => {
  let [message, setMessage] = useState();
  let ok;
  let name;
  let email;
  let phone;
  let home;
  let adress;

  function submitForm(ev) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: title,
        name: name,
        email: email,
        phone: phone,
        adress: adress,
      }),
    };
    fetch("/valuation", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ((data.ok = 1)) setMessage("Sent!");
        else setMessage("something went wrong");
      });
    ev.preventDefault();
  }
  return (
    <div className="valuation">
      <div className="valuation-left">
        <h1 className="valuation__h1">{title}</h1>
        <p className="valuation__h1">{subtitle}</p>

        <form className="valuation-form" onSubmit={(ev) => submitForm(ev)}>
          <div className="valuation-message">{message}</div>
          <label htmlFor="name">Nafn:</label>
          <input
            type="text"
            name="name"
            placeholder="Jón"
            value={name}
            onChange={(change) => (name = change.target.value)}
          />
          <label htmlFor="email">Netfang:</label>
          <input
            type="email"
            name="email"
            placeholder="jón@gmail.com"
            value={email}
            onChange={(change) => (email = change.target.value)}
          />
          <label htmlFor="phone">Sími:</label>
          <input
            type="tel"
            name="phone"
            placeholder="555 5555"
            value={phone}
            onChange={(change) => (phone = change.target.value)}
          />
          {/* <label htmlFor="adress">Heimili:</label>
          <input
            type="text"
            name="adress"
            placeholder="Kirkjuteigur 9"
            value={home}
            onChange={(change) => (home = change.target.value)}
          /> */}
          <label htmlFor="adress-2">
            Heimilisfang fasteignar{` ${action}`}:
          </label>
          <input
            type="text"
            name="adress-2"
            placeholder="Dúfnahólar 10"
            value={adress}
            onChange={(change) => (adress = change.target.value)}
          />
          <input type="submit" value="Senda" />
        </form>
        {/* <Link to="/valuation" className="valuation-back">
          <img
            src={svg}
            alt="back arrow"
            className="valuation-card-arrow valuation-arrow-back"
          />
          Til baka
        </Link> */}
      </div>
      <div
        className="valuation-right"
        style={{ backgroundImage: `url(${bvbImg})` }}
      ></div>
    </div>
  );
};

export default ValuationForm;
