import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "../css/Contact.css";
import contactImg from "../img/bvb-4.jpg";
const Contact = () => {
  const [userName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const submitForm = (ev) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: "Hafa Samband",
        name: userName,
        email: email,
        message: message,
      }),
    };
    fetch("/contact", requestOptions)
      .then((response) => response.json())
      .then(({ ok }) => {
        console.log(ok);
        if (ok === 1) setInfo("Sent!");
        else setError("something went wrong");
      });
    ev.preventDefault();
  };

  return (
    <>
      <div className="contact">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hafa Samband</title>
          <link rel="canonical" href="https://birgirvalur.is" />
        </Helmet>
        <img src={contactImg} alt="" className="contact__img" />
        <form onSubmit={(ev) => submitForm(ev)} className="contact-form">
          <span className="valuation-message">{info || error}</span>
          <label htmlFor="name">Nafn:</label>
          <input
            type="text"
            value={userName}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <br />
          <label htmlFor="name">Netfang:</label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <br />
          <label htmlFor="name">Skilaboð:</label>
          <textarea
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <br />
          <button type="submit">Senda</button>
        </form>
      </div>
    </>
  );
};

export default Contact;
