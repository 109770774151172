import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import noPhoto from "../svg/no-photo.svg";

import "../css/News.css";

const News = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    fetch("/news-articles")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setNewsArticles(data);
          setPending(false);
        } else setError("something went wrong");
      });
  }, []);

  return (
    <div className="news">
      <h1 className="news__h1">Pistlar</h1>
      {pending && <p>Hleður...</p>}
      {newsArticles && (
        <div className="news-articles-container">
          {newsArticles.map(
            ({
              imgUrl = noPhoto,
              title = "Engin titill",
              introduction = "",
              id = Math.random() * 5,
            }) => (
              <div className="news-article" key={id}>
                <img
                  className="news-article__img"
                  src={`https:${imgUrl.fields.file.url}`}
                />
                <h1 className="news-article__h1">{title}</h1>
                <p className="news-article__p">{introduction}</p>
                <Link className="news-article-link" to={`/news/${id}`}>
                  Lesa meira
                </Link>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default News;
