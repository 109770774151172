import Cursor from "./cursor";
import AnimateRealEstateItems from "./AnimateRealEstateItems";

const Animate = () => {
  // Remove loader (loading class)
  document.body.classList.remove("loading");

  // Initialize custom cursor
  //   const cursor = new Cursor(document.querySelector(".cursor"));

  // Initialize the AnimateRealEstateItems
  new AnimateRealEstateItems(document.querySelector(".real-estate"));

  // Mouse effects on all links and others
  //   [...document.querySelectorAll("a, .real-estate__item-img")].forEach(
  //     (link) => {
  //       link.addEventListener("mouseenter", () => cursor.enter());
  //       link.addEventListener("mouseleave", () => cursor.leave());
  //     }
  //   );
};

export default Animate;
