import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../css/Valuation.css";
import svg from "../svg/arrow.svg";
import bvbImg from "../img/bvb-4.jpg";

const Valuation = () => {
  return (
    <div className="valuation">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Frítt Verðmat</title>
        <link rel="canonical" href="https://birgirvalur.is" />
      </Helmet>
      <div className="valuation-left">
        <h1 className="valuation__h1">Hver er staðan þín?</h1>
        <Link to="/free-valuation" className="valuation-card">
          <p className="valuation-card__p">Mig vantar frítt verðmat</p>
          <img src={svg} alt="arrow icon" className="valuation-card-arrow" />
        </Link>
        <Link to="/bank-valuation" className="valuation-card">
          <p className="valuation-card__p">Mig vantar banka verðmat</p>
          <img src={svg} alt="arrow icon" className="valuation-card-arrow" />
        </Link>
        <Link to="/register-valuation" className="valuation-card">
          <p className="valuation-card__p">Mig langar að skrá eign</p>
          <img src={svg} alt="arrow icon" className="valuation-card-arrow" />
        </Link>
      </div>
      <div
        className="valuation-right"
        style={{ backgroundImage: `url(${bvbImg})` }}
      ></div>
    </div>
  );
};
export default Valuation;
