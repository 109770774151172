// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position
const getMousePos = (e) => {
  return {
    x: e.clientX,
    y: e.clientY,
  };
};

const calcWinsize = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

const getRandomInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export { lerp, getMousePos, calcWinsize, getRandomInteger };
