import { Link } from "react-router-dom";
import "../css/Decision.css";

const Decision = () => {
  return (
    <div className="decision-wrapper">
      <div className="decision-sell">
        <div className="decision-text-wrapper">
          <div className="bg"></div>
          <h1 className="decision__h1">Söluráðgjöf</h1>
          <p className="decision__p">
            Hafðu samband og bókaðu fund þar sem við förum yfir þína eign, þínar
            væntingar til dæmis hvað varðar verð og sölutíma. Söluráðgjöf má í
            raun skipta í þrennt, fyrst má nefna verðmat en það er ómissandi
            hluti af söluferlinu. Í öðru lagi má nefna þau atriði sem tryggja
            þér hámarks söluverð og í þriðja lagi verður sett upp söluáætlun þar
            sem við förum yfir allt ferlið í heild.
          </p>
          <Link to="/free-valuation" className="decision__cta">
            Frítt Verðmat
          </Link>
        </div>
      </div>
      <div className="decision-advice">
        <div className="decision-text-wrapper">
          <div className="bg"></div>
          <h1 className="decision__h1">Kaupráðgjöf</h1>
          <p className="decision__p">
            Hafðu samband og bókaðu fund þar sem við förum yfir þínar þarfir til
            dæmis hvað varðar staðsetningu, verð og stærð eignarinnar. Í
            kaupráðgjöf er farið yfir verð eigna eftir hverfum og tegund
            eignarinnar, þ.e. sérbýli eða fjölbýli. Einnig er farið yfir þau
            atriði sem þarf að hafa í huga við kaup á fasteignum. .
          </p>
          <Link to="/contact" className="decision__cta">
            Hafa Samband
          </Link>
        </div>
      </div>
      <div className="decision-buy">
        <div className="decision-text-wrapper">
          <h1 className="decision__h1">Verðmat</h1>
          <p className="decision__p">
            Verðmat er undirstöðuatriði þegar kemur að sölu fasteigna. Þær
            fasteignir sem eru verðlagðar of hátt seljast ekki og þær fasteignir
            sem verðlagðar eru of lágt skila ekki seljanda hámarks söluverði.
            Það er því afar mikilvægt að gera eins nákvæmt verðmat og mögulegt
            er. Þegar ég geri verðmat byggi ég það á raungögnum og þekkingu af
            fasteignamarkaðnum. Það er mitt markmið að verðmat frá mér
            endurspegli það verð sem þú getur raunverulega átt von á að fá fyrir
            þína fasteign.
          </p>
          <Link to="/contact" className="decision__cta">
            Hafa Samband
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Decision;
