import { Link } from "react-router-dom";
import bvbImg from "../img/bvb-2.jpg";
const About = () => {
  return (
    <div className="about">
      <div className="about-text">
        <h1 className="about__h1">Birgir Valur Birgisson</h1>
        <h2 className="about__h2">Aðstoðarmaður fasteignasala</h2>
        <p className="about__p">
          Metnaður, áreiðanleiki og heiðarleiki er það sem ég stend fyrst og
          fremst fyrir. Ég legg upp úr því að veita framúrskarandi þjónustu og
          tryggi að þínir hagsmunir verði alltaf hafðir í forgrunni. Ásamt því
          að vera í námi til löggildingar fasteigna- og skipasala þá er ég að
          útskrifast með BA gráðu i lögfræði frá Háskólanum í Reykjavík. Ég nýti
          þekkingu mína úr lögfræðináminu í öllu sem ég tek mér fyrir hendur og
          nýtist hún einstaklega vel í starfi mínu. Samhliða því að tryggja að
          söluferlið allt sé í samræmi við lög og reglur fæst einnig með þessu
          móti hámarks söluverð fyrir þína fasteign.
        </p>
        <p className="about__p">
          Ég starfa undir handleiðslu Jón Guðna Sandholt, löggilts
          fasteignasala.
        </p>

        <Link to="/about" className="about__cta">
          Lesa Meira
        </Link>
      </div>
      <img src={bvbImg} alt="an img of Birgir" className="about__img" />
    </div>
  );
};
export default About;
