const Footer = () => {
  return (
    <footer>
      <div className="footer-collumn">
        <h4 className="footer__h4">Birgir Valur Birgisson</h4>
        <p className="footer__p">Aðstoðarmaður fasteignasala</p>
      </div>
      <div className="footer-collumn">
        <h4 className="footer__h4">Hafa Samband</h4>
        <p className="footer__p">(354) 694 8474</p>
      </div>
    </footer>
  );
};

export default Footer;
