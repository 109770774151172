import { Link } from "react-router-dom";
import { useState } from "react";
import useWindowDimensions from "../utils/useWindowWidth";

const Nav = ({ color, logoColor }) => {
  const [isActive, setActive] = useState("");
  const { width } = useWindowDimensions();

  // opens and closes the hamburger menu
  function switchActive() {
    if (isActive === "") {
      setActive("top-nav--hamburger-active");
    }
    if (isActive === "top-nav--hamburger-active") {
      setActive("");
    }
  }
  function closeNav() {
    if (isActive === "top-nav--hamburger-active") {
      setActive("");
    }
  }

  return (
    <nav
      className="top-nav"
      style={{ "--nav-color": color, "--nav-logo-color": logoColor }}
    >
      <ul className="top-nav__ul">
        <li className="top-nav__li top-nav--logo">
          <Link to="/" className="top-nav__a top-nav__li--logo">
            {width < 400
              ? "Birgir Valur Birgisson"
              : "Birgir Valur Birgisson | Alda"}
          </Link>
        </li>

        <div className={`top-nav__li--container ${isActive}`}>
          <li className="top-nav__li">
            <Link
              to="/free-valuation"
              className="top-nav__a"
              onClick={() => closeNav()}
            >
              Frítt Verðmat
            </Link>
          </li>
          <li className="top-nav__li">
            <Link
              to="/register-valuation"
              className="top-nav__a"
              onClick={() => closeNav()}
            >
              Skrá Eign
            </Link>
          </li>
          {/* <li className="top-nav__li">
            <Link to="/real-estate" className="top-nav__a">
              Fasteignir
            </Link>
          </li> */}
          <li className="top-nav__li">
            <Link to="/about" className="top-nav__a" onClick={() => closeNav()}>
              Um Mig
            </Link>
          </li>
          <li className="top-nav__li">
            <Link
              to="/contact"
              className="top-nav__a"
              onClick={() => closeNav()}
            >
              Hafa Samband
            </Link>
          </li>
          <li
            className={`top-nav__li top-nav--hamburger ${isActive}`}
            onClick={switchActive}
          ></li>
        </div>
      </ul>
    </nav>
  );
};

export default Nav;
