// dependancies
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import ScrollToTop from "./componments/ScrollToTop";

// sites and components
import Home from "./Home";
import Nav from "./componments/Nav";
import Footer from "./componments/Footer";
import Valuation from "./sites/Valuation";
import ValuationForm from "./componments/ValuationForm";
import RealEstate from "./sites/RealEstate";
import Estate from "./componments/Estate";
import SingleNews from "./componments/SingleNews";
import News from "./componments/News";
import Contact from "./sites/Contact";
import About from "./sites/About";
import SplashScreen from "./componments/SplashScreen";

// styling (css)
import "./css/Nav.css";
import "./css/Footer.css";

const App = () => {
  const [hasDomLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    // setTimeout(() => {
    setDomLoaded(true);
    // }, 500);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/contact">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <Contact />
        </Route>
        <Route path="/about">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <About />
        </Route>
        <Route path="/news/:id">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <SingleNews />
        </Route>
        <Route path="/news">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <News />
        </Route>
        <Route path="/real-estate/:id">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <Estate />
        </Route>
        <Route path="/real-estate">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <RealEstate />
        </Route>

        <Route path="/valuation">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <Valuation />
        </Route>
        <Route path="/bank-valuation">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <ValuationForm title={"Banka verðmat"} />
        </Route>
        <Route path="/free-valuation">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <ValuationForm title={"Frítt verðmat"} />
        </Route>
        <Route path="/register-valuation">
          <Nav color={"rgb(30, 30, 30)"} logoColor={"black"} />
          <ValuationForm
            title={"Skrá eign til sölu"}
            action={"sem á að skrá til sölu"}
          />
        </Route>
        <Route exact path="/">
          <Nav color={"white"} logoColor={"white"} />
          <SplashScreen hasDomLoaded={hasDomLoaded} />
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};
export default App;
