import "../css/SplashScreen.css";

const SplashScreen = ({ hasDomLoaded: domLoaded }) => {
  if (!domLoaded) {
    return (
      <div className="splash-screen">
        <h1 className="splash-screen__h1">Birgir Valur Birgisson</h1>
      </div>
    );
  } else {
    return null;
  }
};

export default SplashScreen;
